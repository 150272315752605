import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import LayoutUpdate from "../components/layout-update"
import Seo from "../components/seo"
import HeroHomeUpdateNewVersion from "../components/sections/hero-home-update-new-version"
import HomeBannerSectionUpdate from "../components/sections/home-banner-section-update"
import IntroHomeUpdate from "../components/sections/intro-home-update"
import PlansSectionNew from "../components/sections/plans-section-new"
import SectionMobileCTAnew from "../components/sections/section-mobile-cta-new"
import SectionMissionUpdateNew from "../components/sections/section-mission-update-new"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query IndexPageQuery {
      prismicHomePage {
        data {
          page_title
          page_short_description
        }
      }
    }
  `)

  const doc = data.prismicHomePage

  return (
    <LayoutUpdate>
      <Seo
        title={doc.data.page_title ? doc.data.page_title : "SRA 831(b) for Business Owners"}
        description={doc.data.page_short_description ? doc.data.page_short_description : "SRA has made it possible for any successful business to take advantage of the same tools previously reserved for Fortune 500 Companies and utilize an 831(b) Plan to cover uncommon, underinsured or uninsured risks with tax-deferred funds."}
      />

      <div className="hero-section">
        <HeroHomeUpdateNewVersion />
      </div>
      <HomeBannerSectionUpdate />
      <IntroHomeUpdate />
      <SectionMissionUpdateNew />
      <PlansSectionNew />    
      <SectionMobileCTAnew />
    </LayoutUpdate>
  )
}

export default IndexPage
